
.theory-list{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .theory-list-header{
    display: flex;
    align-items: center;
    span{
      line-height: 1;
    }
  }
}
